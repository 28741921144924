import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu.tsx';
import { Button } from '@/components/ui/button.tsx';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar.tsx';
import { getNameAbbreviation } from '@/lib/name-abbreviation.ts';
import { User } from '@/model/User.ts';
import { useAuthService } from '@/service/AuthService.ts';
import { useNavigate } from 'react-router-dom';

type UserDropdownMenuProps = {
  user: User;
};

const UserDropdownMenu = (props: UserDropdownMenuProps) => {
  const authService = useAuthService();
  const navigate = useNavigate();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="secondary" size="icon" className="rounded-full m-1 h-10 w-10 focus-visible:ring-0">
          <Avatar>
            <AvatarImage src="" />
            <AvatarFallback className="border-2 border-violet-700">
              {getNameAbbreviation(props?.user.fullName)}
            </AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>{props?.user.fullName}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => navigate('/history')}>History</DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => authService.logout()}>Logout</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default UserDropdownMenu;
