const EVENT_TITLE = 'New iMind Meeting';
const EVENT_DETAILS = 'Click the link to join the meeting:';

export function createEventInGoogleCalendar(conferenceRoomUrl: string) {
  const encodedTitle = encodeURIComponent(EVENT_TITLE);
  const encodedDetails = encodeURIComponent(`${EVENT_DETAILS} ${conferenceRoomUrl}`);
  const encodedLocation = encodeURIComponent(conferenceRoomUrl);

  const googleCalendarUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodedTitle}&details=${encodedDetails}&location=${encodedLocation}`;

  window.open(googleCalendarUrl, '_blank');
}
