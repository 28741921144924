import { FC } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogPortal,
  DialogTitle,
} from '@/components/ui/dialog';
import { FaCopy } from 'react-icons/fa';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { LoadingSpinner } from '@/components/ui/spinner.tsx';

type Props = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  link: string | null;
};

export const ConferenceLinkDialog: FC<Props> = (props) => {
  return (
    <Dialog open={props.open} onOpenChange={props.onOpenChange}>
      <DialogPortal>
        <DialogContent className="max-w-[90%] sm:max-w-md mx-auto rounded-lg">
          <DialogHeader>
            <DialogTitle>Here's your joining info</DialogTitle>
          </DialogHeader>

          {!props.link ? (
            <div className="flex justify-center items-center h-32">
              <LoadingSpinner size={42} />
            </div>
          ) : (
            <>
              <DialogDescription>
                Send this to people you want to meet with. Be sure to save it so you can use it later.
              </DialogDescription>
              <div className="flex items-center space-x-2">
                <div className="grid flex-1 gap-2">
                  <Label htmlFor="link" className="sr-only">
                    Link
                  </Label>
                  {/*kludge: Set 'tabIndex' to avoid receiving focus after dialog creation */}
                  <Input id="link" value={props.link} readOnly tabIndex={-1} className="w-full" />
                </div>
                <Button
                  type="submit"
                  size="sm"
                  className="px-3 bg-white text-black hover:bg-gray-200 hover:text-gray-500"
                  onClick={async () => await navigator.clipboard.writeText(props.link!)}
                >
                  <span className="sr-only">Copy link</span>
                  <FaCopy className="h-4 w-4 text-black" />
                </Button>
              </div>
            </>
          )}
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};

export default ConferenceLinkDialog;
