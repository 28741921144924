import type { StateCreator } from 'zustand';
import { create as _create } from 'zustand';

const storeResetFns = new Set<() => void>();
export const resetAllStores = (): void => storeResetFns.forEach((resetFn) => resetFn());

export const create = (<T>(f: StateCreator<T> | undefined) => {
  if (f === undefined) return create;
  const store = _create(f);
  const initialState = store.getState();
  storeResetFns.add(() => store.setState(initialState, true));
  return store;
}) as typeof _create;
