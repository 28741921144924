import { MediaStreamAudioBuffer, MediaStreamAudioConsumer } from 'mind-sdk-web';
import { useAudioAnalizerStore } from '@/store/AudioAnalizerStore.ts';

export class AudioStreamListener implements MediaStreamAudioConsumer {
  constructor(private readonly participantId: string) {}

  onAudioBuffer(buffer: MediaStreamAudioBuffer): void {
    if (buffer) {
      const audioTrack = buffer.getTrack();
      const stream = new MediaStream([audioTrack]);

      const audioContext = new AudioContext();
      const analyser = audioContext.createAnalyser();
      const source = audioContext.createMediaStreamSource(stream);
      source.connect(analyser);
      useAudioAnalizerStore.getState().addAnalyzer(this.participantId, analyser);
    }
  }
}
