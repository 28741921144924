import { MediaStream } from 'mind-sdk-web';

export class TestParticipant {
  private readonly _id: string;
  private readonly _name: string;

  constructor(id: string, name: string) {
    this._id = id;
    this._name = name;
  }

  getId(): string {
    return this._id;
  }

  getName(): string {
    return this._name;
  }

  getMediaStream(): MediaStream | null {
    return null;
  }

  getSecondaryMediaStream(): MediaStream | null {
    return null;
  }

  isStreamingSecondaryVideo(): boolean {
    return false;
  }
}
