import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import './index.css';
import HomePage from './pages/HomePage.tsx';
import { lazy, Suspense, useCallback, useEffect, useRef } from 'react';
import MessageSheet from '@/components/MessageSheet.tsx';
import { useAuthService } from '@/service/AuthService.ts';
import { resetAllStores } from '@/store/create-disposable-store.ts';
import { useConferenceService } from '@/service/ConferenceService.ts';
import { Toaster } from '@/components/ui/sonner.tsx';

const AuthPage = lazy(() => import('@/pages/AuthPage.tsx'));
const HistoryPage = lazy(() => import('@/pages/HistoryPage.tsx'));
const VersionPage = lazy(() => import('@/pages/VersionPage.tsx'));
const ConferencePage = lazy(() => import('@/pages/conference/ConferencePage.tsx'));

const DEFAULT_FALLBACK = <MessageSheet message="Loading..." />;

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route index element={<HomePage />} />

      <Route
        path="auth"
        element={
          <Suspense fallback={DEFAULT_FALLBACK}>
            <AuthPage />
          </Suspense>
        }
      />

      <Route
        path="history"
        element={
          <Suspense fallback={DEFAULT_FALLBACK}>
            <HistoryPage />
          </Suspense>
        }
      />

      <Route
        path=":roomId"
        element={
          <Suspense fallback={DEFAULT_FALLBACK}>
            <ConferencePage />
          </Suspense>
        }
      />

      <Route
        path="version"
        element={
          <Suspense fallback={DEFAULT_FALLBACK}>
            <VersionPage />
          </Suspense>
        }
      />
    </Route>
  )
);

function App() {
  const authService = useAuthService();
  const conferenceService = useConferenceService();
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  const scheduleTokenRefresh = useCallback((): void => {
    const expIn: number | null = authService.getExpiresIn();
    if (expIn) {
      const timeout = expIn > 5000 ? expIn - 5000 : 0; // 5 seconds before expiry
      timeoutIdRef.current = setTimeout(async () => {
        await authService.refresh();
        scheduleTokenRefresh();
      }, timeout);
    }
  }, [authService]);

  useEffect(() => {
    resetAllStores();
    scheduleTokenRefresh();

    return () => {
      timeoutIdRef.current && clearTimeout(timeoutIdRef.current);
      conferenceService.closeSession();
      resetAllStores();
    };
  }, [conferenceService, scheduleTokenRefresh]);

  useEffect(() => {
    window.onpageshow = function (event) {
      if (event.persisted) {
        window.location.reload();
      }
    };
  }, []);

  return (
    <>
      <RouterProvider router={router} />
      <Toaster position="top-right" />
    </>
  );
}

export default App;
