import { Participant, ScreenListener } from 'mind-sdk-web';
import { useConferenceStore } from '@/store/ConferenceStore.ts';
import { useDeviceStore } from '@/store/DeviceStore.ts';

export class ScreenEventListener extends ScreenListener {
  constructor() {
    super();
  }

  onScreenReleasedForcibly() {
    const me = useConferenceStore.getState().session?.getConference()?.getMe();
    useConferenceStore.getState().session?.fireOnParticipantSecondaryMediaChanged(me as Participant);

    useDeviceStore.getState().setScreenPresentingEnabled(false);
  }
}
