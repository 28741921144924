const FIRST_NAMES = [
  'Aaryn',
  'Aayan',
  'Aazaan',
  'Bradan',
  'Braden',
  'Bradley',
  'Cain',
  'Caine',
  'Cairn',
  'Dagon',
  'Dailey',
  'Daimhin',
  'Efan',
  'Efe',
  'Ege',
  'Glen',
  'Glenn',
  'Gordon',
];

const LAST_NAMES = [
  'Amrein',
  'Amrhein',
  'Amrich',
  'Blankenship',
  'Blankinship',
  'Blankley',
  'Channing',
  'Chanofsky',
  'Chanoine',
  'Demastus',
  'Demateo',
  'Dematos',
  'Espiritu',
  'Espitia',
  'Esplain',
  'Fixico',
  'Fizer',
  'Fjeld',
];

const capFirst = (name: string): string => name.charAt(0).toUpperCase() + name.slice(1);

const getRandomInt = (min: number, max: number): number => Math.floor(Math.random() * (max - min)) + min;

export const generateName = () =>
  capFirst(FIRST_NAMES[getRandomInt(0, FIRST_NAMES.length - 1)]) +
  ' ' +
  capFirst(LAST_NAMES[getRandomInt(0, LAST_NAMES.length - 1)]);

export function createRandomString(length: number): string {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
}
