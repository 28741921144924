import { v4 as uuid } from 'uuid';

export class User {
  constructor(
    public readonly id: string,
    public readonly email: string,
    public readonly firstName: string,
    public readonly lastName: string
  ) {
    if (!id) {
      this.id = uuid();
    }
  }

  get fullName(): string {
    return [this.firstName || '', this.lastName || ''].filter(Boolean).join(' ');
  }
}

export class AnonymousUser extends User {
  constructor(
    public readonly id: string,
    public readonly name: string
  ) {
    super(id, '', name, '');
  }

  static fromJson(json: string): AnonymousUser {
    const data = JSON.parse(json);
    return new AnonymousUser(data.id, data.name);
  }
}
