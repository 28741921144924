import { useDeviceStore } from '@/store/DeviceStore';
import { MicrophoneListener } from 'mind-sdk-web';

export class MicrophoneEventListener extends MicrophoneListener {
  constructor() {
    super();
  }

  onMicrophoneReleasedForcibly() {
    useDeviceStore.getState().setMicrophoneEnabled(false);
  }
}
