import moment from 'moment';
import { FC, useEffect, useState } from 'react';

const DEFAULT_TIME_FORMAT = 'HH:mm';

type Props = {
  className?: string;
  format?: string;
};

export const Clock: FC<Props> = (props) => {
  const [time, setTime] = useState(moment().format(props.format || DEFAULT_TIME_FORMAT));

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(moment().format(props.format || DEFAULT_TIME_FORMAT));
    }, 10000);
    return () => clearInterval(interval);
  }, [props.format]);

  return <div className={props.className}>{time}</div>;
};
