import Header from '../components/Header';
import CreateConferenceButton from '@/components/button/CreateConferenceButton.tsx';

import MessageSheet from '@/components/MessageSheet.tsx';
import { useUIStore } from '@/store/UIStore.ts';

const HomePage = () => {
  const pendingOperationMessage = useUIStore((state) => state.pendingOperationMessage);
  return (
    <>
      <div className="relative flex flex-col h-full w-full">
        <Header />
        <main className="flex flex-col items-center justify-center h-[calc(100vh-8rem)] md:h-[calc(100vh-4rem)] w-full gap-4 px-4">
          <div className="max-w-sm md:max-w-lg flex flex-col flex-grow gap-6 justify-center">
            <h1 className="text-3xl md:text-5xl">Video calls and meetings for your business</h1>
            <h2 className="text-lg text-muted-foreground">Connect, collaborate and accelerate success</h2>
            <CreateConferenceButton />
          </div>
        </main>

        {pendingOperationMessage && (
          <div className="absolute inset-0 flex justify-center items-center bg-white z-50">
            <MessageSheet message={pendingOperationMessage} />
          </div>
        )}
      </div>
    </>
  );
};

export default HomePage;
