import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useRef } from 'react';

export function useTransientNavigate<T>() {
  const navigate = useNavigate();
  const location = useLocation();
  const stateRef = useRef<T | null>(location.state as T);

  const transientNavigate = useCallback(
    (to: string, state: T) => {
      navigate(to, { state });
    },
    [navigate]
  );

  const getTransientState = useCallback((): T | null => {
    const state = stateRef.current;

    // Clear the state after retrieval
    if (state) {
      stateRef.current = null;
      // Use setTimeout to defer the state clearing in the router
      setTimeout(() => {
        navigate(location.pathname, { replace: true, state: null });
      }, 0);
    }

    return state;
  }, [navigate, location.pathname]);

  return { transientNavigate, getTransientState };
}
