import { Camera, Me, Microphone } from 'mind-sdk-web';
import { IConferenceSDK } from '@/sdk/ConferenceSDK.ts';
import { createConferenceSDK } from '@/lib/conference-sdk.ts';
import { ScreenEventListener } from '@/event/ScreenEventListener.ts';
import { useDeviceStore } from '@/store/DeviceStore.ts';
import { MicrophoneEventListener } from '@/event/MicrophoneEventListener.ts';
import { CameraEventListener } from '@/event/CameraEventListener.ts';
import { toast } from 'sonner';
import { create } from '@/store/create-disposable-store.ts';
import { AudioStreamListener } from '@/event/AudioStreamListener.ts';
import { useConferenceStore } from '@/store/ConferenceStore.ts';
import { useSelectedDeviceStore } from '@/store/SelectedDeviceStore.ts';

export const getDeviceService = (): DeviceService => useDeviceServiceStore.getState().deviceService;
export const useDeviceService = (): DeviceService => useDeviceServiceStore((state) => state.deviceService);

export interface DeviceService {
  getMicrophones(): Microphone[];
  getCameras(): Camera[];
  useDefaultDevices(): Promise<void>;
  useMicrophone(microphone: Microphone): Promise<void>;
  toggleMicrophone: () => Promise<void>;
  useCamera(camera: Camera): Promise<void>;
  toggleCamera: () => Promise<void>;
  setupPrimaryMediaStream(me: Me | null): Promise<void>;
  setupSecondaryMediaStream(me: Me | null): Promise<void>;
}

export class DeviceServiceImpl implements DeviceService {
  private readonly _sdk: IConferenceSDK;

  constructor() {
    this._sdk = createConferenceSDK();
  }

  getMicrophones(): Microphone[] {
    return this._sdk.getDeviceRegistry().getMicrophones();
  }

  getCameras(): Camera[] {
    return this._sdk.getDeviceRegistry().getCameras();
  }

  async useDefaultDevices(): Promise<void> {
    const microphones = this.getMicrophones();
    const prevSelectedMicId = useSelectedDeviceStore.getState().selectedMicrophoneId;
    const mic = microphones.find((mic) => mic.getId() === prevSelectedMicId);
    if (mic) {
      await this.useMicrophone(mic);
    } else {
      await this.useMicrophone(microphones[0]);
    }

    const cameras = this.getCameras();
    const prevSelectedCamId = useSelectedDeviceStore.getState().selectedCameraId;
    const cam = cameras.find((cam) => cam.getId() === prevSelectedCamId);
    if (cam) {
      await this.useCamera(cam);
    } else {
      await this.useCamera(cameras[0]);
    }
  }

  async useMicrophone(microphone: Microphone): Promise<void> {
    const deviceStoreState = useDeviceStore.getState();
    const selectedDeviceStoreState = useSelectedDeviceStore.getState();
    const prevMic = deviceStoreState.microphone;

    try {
      prevMic && (await prevMic.release());

      if (microphone) {
        if (deviceStoreState.microphoneEnabled) {
          await microphone.acquire();
        }

        microphone.setNoiseSuppression(true);
        microphone.setListener(new MicrophoneEventListener());
        selectedDeviceStoreState.setMicrophoneId(microphone.getId());
      }

      deviceStoreState.setMicrophone(microphone);

      const me = useConferenceStore.getState().me;
      await this.setupPrimaryMediaStream(me);
    } catch (e) {
      toast.error(`Can't switch microphone: ${e.message}`);
    }
  }

  async toggleMicrophone(): Promise<void> {
    const state = useDeviceStore.getState();
    try {
      if (!state.microphone) return;

      state.microphoneEnabled ? await state.microphone?.release() : await state.microphone?.acquire();
      state.microphone?.setMuted(state.microphoneEnabled);
      state.setMicrophoneEnabled(!state.microphoneEnabled);
    } catch (e) {
      toast.error(`Can't ${state.microphoneEnabled ? 'stop' : 'start'} microphone: ${e.message}`);
    }
  }

  async useCamera(camera: Camera): Promise<void> {
    const deviceStoreState = useDeviceStore.getState();
    const selectedDeviceStoreState = useSelectedDeviceStore.getState();
    const prevCamera = deviceStoreState.camera;

    try {
      prevCamera && (await prevCamera.release());

      if (camera) {
        if (deviceStoreState.cameraEnabled) {
          await camera.acquire();
        }

        selectedDeviceStoreState.setCameraId(camera.getId());
        camera.setListener(new CameraEventListener());
      }

      deviceStoreState.setCamera(camera);

      const me = useConferenceStore.getState().me;
      await this.setupPrimaryMediaStream(me);
    } catch (e) {
      toast.error(`Can't switch camera: ${e.message}`);
    }
  }

  async toggleCamera(): Promise<void> {
    const state = useDeviceStore.getState();

    try {
      if (!state.camera) return;

      state.cameraEnabled ? await state.camera?.release() : await state.camera?.acquire();
      state.setCameraEnabled(!state.cameraEnabled);
    } catch (e) {
      toast.error(`Can't ${state.cameraEnabled ? 'stop' : 'start'} camera: ${e.message}`);
    }
  }

  async setupPrimaryMediaStream(me: Me | null): Promise<void> {
    const microphone = useDeviceStore.getState().microphone;
    const camera = useDeviceStore.getState().camera;

    const primaryMediaStream = microphone || camera ? this._sdk.createMediaStream(microphone, camera) : null;
    useDeviceStore.getState().setPrimaryMediaStream(primaryMediaStream);

    if (me) {
      primaryMediaStream && primaryMediaStream.addAudioConsumer(new AudioStreamListener(me.getId()));
      me.setMediaStream(primaryMediaStream);
    }
  }

  async setupSecondaryMediaStream(me: Me | null): Promise<void> {
    const deviceRegistry = this._sdk.getDeviceRegistry();
    const screen = deviceRegistry.getScreen();
    if (screen) {
      screen.setListener(new ScreenEventListener());
      useDeviceStore.getState().setScreen(screen);
    }

    const secondaryMediaStream = this._sdk.createMediaStream(screen, screen);
    useDeviceStore.getState().setSecondaryMediaStream(secondaryMediaStream);
    if (secondaryMediaStream && me) {
      me.setSecondaryMediaStream(secondaryMediaStream);
    }
  }
}

const useDeviceServiceStore = create(() => ({
  deviceService: new DeviceServiceImpl(),
}));
