import { useDeviceStore } from '@/store/DeviceStore';
import { CameraListener } from 'mind-sdk-web';

export class CameraEventListener extends CameraListener {
  constructor() {
    super();
  }

  onCameraReleasedForcibly() {
    useDeviceStore.getState().setCameraEnabled(false);
  }
}
