import * as React from 'react';
import { FC } from 'react';
import { cn } from '@/lib/utils';
import { TbLoader2 } from 'react-icons/tb';

interface LoadingSpinnerProps extends React.HTMLAttributes<SVGSVGElement> {
  className?: string;
  size?: number;
}

const LoadingSpinner: FC<LoadingSpinnerProps> = (props) => {
  const { className, ...rest } = props;
  return <TbLoader2 className={cn(`w-${props.size} h-${props.size} rounded-full animate-spin`, className)} {...rest} />;
};

LoadingSpinner.displayName = 'LoadingSpinner';

export { LoadingSpinner };
