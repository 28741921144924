import { create } from './create-disposable-store.ts';

export interface AudioAnalizerStore {
  analyzers: Map<string, AnalyserNode>;
  addAnalyzer: (participantId: string, analyser: AnalyserNode) => void;
  removeAnalyzer: (participantId: string) => void;
  removeAllAnalyzer: () => void;
}

export const useAudioAnalizerStore = create<AudioAnalizerStore>()((set) => ({
  analyzers: new Map<string, AnalyserNode>(),

  addAnalyzer: (participantId: string, analyser: AnalyserNode) => {
    set((state: AudioAnalizerStore) => {
      state.analyzers.set(participantId, analyser);
      return { analyzers: state.analyzers };
    });
  },

  removeAnalyzer: (participantId: string) => {
    set((state: AudioAnalizerStore) => {
      state.analyzers.get(participantId)?.disconnect();
      state.analyzers.delete(participantId);
      return { analyzers: state.analyzers };
    });
  },

  removeAllAnalyzer: () => {
    set((state: AudioAnalizerStore) => {
      state.analyzers.forEach((analyzer) => {
        analyzer.disconnect();
      });
      state.analyzers.clear();
      return { analyzers: state.analyzers };
    });
  },
}));
