import { Button } from '@/components/ui/button';
import { DropdownMenu, DropdownMenuTrigger } from '@/components/ui/dropdown-menu.tsx';
import CreateConferenceMenu from '@/components/CreateConferenceMenu.tsx';

export default function CreateConferenceButton() {
  return (
    <div className="flex gap-2 items-center">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button className="h-12 rounded-3xl bg-gradient-to-r from-blue-500 to-violet-700 hover:opacity-90 focus-visible:ring-0">
            New conference
          </Button>
        </DropdownMenuTrigger>
        <CreateConferenceMenu />
      </DropdownMenu>
    </div>
  );
}
