import { useEffect, useState } from 'react';
import { AnonymousUser, User } from '@/model/User.ts';
import { useAuthService } from '@/service/AuthService.ts';

export function useUser(): { user: User | null; loading: boolean } {
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<User | AnonymousUser | null>(null);

  const authService = useAuthService();

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const user = await authService.getUser();
        setUser(user);
      } finally {
        setLoading(false);
      }
    })();
  }, [authService]);

  return { user: user, loading };
}
