import { create, StateCreator } from 'zustand';
import { createJSONStorage, persist, PersistOptions } from 'zustand/middleware';

export interface SelectedDeviceStore {
  selectedMicrophoneId: string | null;
  setMicrophoneId: (microphoneId: string | null) => void;

  selectedCameraId: string | null;
  setCameraId: (cameraId: string | null) => void;
}

type SelectedDeviceStorePersist = (
  config: StateCreator<SelectedDeviceStore>,
  options: PersistOptions<SelectedDeviceStore>
) => StateCreator<SelectedDeviceStore>;

export const useSelectedDeviceStore = create<SelectedDeviceStore>(
  (persist as SelectedDeviceStorePersist)(
    (set) => ({
      selectedMicrophoneId: null,
      setMicrophoneId: (microphoneId: string | null) => set({ selectedMicrophoneId: microphoneId }),

      selectedCameraId: null,
      setCameraId: (cameraId: string | null) => set({ selectedCameraId: cameraId }),
    }),
    {
      name: 'selected_devices',
      storage: createJSONStorage(() => localStorage),
    }
  )
);
