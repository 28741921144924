import { Me, Participant, Session } from 'mind-sdk-web';
import { TestParticipant } from '@/model/TestParticipant.ts';
import { generateName } from '@/lib/name-generator.ts';
import { create } from '@/store/create-disposable-store.ts';
import { Conference } from '@/sdk/Conference.ts';

export interface ConferenceStore {
  conference: Conference | null;
  setConference: (conference: Conference | null) => void;

  mindToken: string | null;
  setMindToken: (token: string | null) => void;

  session: Session | null;
  setSession: (session: Session | null) => void;

  me: Me | null;
  setMe: (me: Me | null) => void;

  participants: Participant[];
  addParticipant: (participant: Participant) => void;
  addParticipants: (participants: Participant[]) => void;
  removeParticipant: (participant: Participant) => void;

  //TODO: Remove these methods
  testParticipants: TestParticipant[];
  addTestParticipant: () => void;
  updateParticipant: (participant: Participant) => void;
  removeTestParticipant: () => void;
}

const mergeParticipants = (participants: Participant[]) => [
  ...new Map(participants.map((p) => [p.getId(), p])).values(),
];

const filterParticipant = (participants: Participant[], participant: Participant) =>
  participants.filter((p) => p.getId() !== participant.getId());

export const useConferenceStore = create<ConferenceStore>((set) => ({
  conference: null,
  setConference: (conference: Conference | null) => set({ conference }),

  mindToken: null,
  setMindToken: (token: string | null) => set({ mindToken: token }),

  session: null,
  setSession: (session: Session | null) => set({ session }),

  me: null,
  setMe: (me: Me | null) => set({ me }),

  participants: [],

  addParticipants: (participants: Participant[]) =>
    set((state) => ({ participants: mergeParticipants([...state.participants, ...participants]) })),

  addParticipant: (participant: Participant) =>
    set((state) => ({ participants: mergeParticipants([...state.participants, participant]) })),

  updateParticipant: (participant: Participant) =>
    set((state) => ({
      participants: mergeParticipants([...filterParticipant(state.participants, participant), participant]),
    })),

  removeParticipant: (participant: Participant) =>
    set((state) => ({ participants: filterParticipant(state.participants, participant) })),

  //TODO: Remove these methods
  testParticipants: [],
  addTestParticipant: () =>
    set((state) => ({
      testParticipants: [
        ...state.testParticipants,
        new TestParticipant(Math.random().toString(16).slice(2), generateName()),
      ],
    })),
  removeTestParticipant: () =>
    set((state) => ({ testParticipants: state.testParticipants.slice(0, state.testParticipants.length - 1) })),
}));
